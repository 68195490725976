import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef} from "@angular/material/legacy-dialog";
import { CtSerializerHelperService } from '@ctsolution/ct-webapi';
import { LeadDocumentsConfiguration } from '../lead-documents.configuration';
import { CtModelConfiguration, CtModelService } from '@ctsolution/ct-framework';
import { LeadFileRouteData } from 'projects/ct-manager/src/app/_core/route-data/lead-file.route-data';
import { LeadDocumentsFormComponent } from './lead-documents-form/lead-documents-form.component';

@Component({
  selector: 'app-lead-documents-wizard',
  templateUrl: './lead-documents-wizard.component.html',
  styleUrls: ['./lead-documents-wizard.component.scss']
})
export class LeadDocumentsWizardComponent implements OnInit {


  @ViewChild(LeadDocumentsFormComponent) leadDocumentsFormComponent: LeadDocumentsFormComponent | null = null;

  constructor(
    private serializerHelperService: CtSerializerHelperService,
    @Inject(MAT_DIALOG_DATA) public data: LeadDocumentsConfiguration | null,
    private dialogRef: MatLegacyDialogRef<any>,
    private _model: CtModelService<any>) {
  }

  ngOnInit() {

    if (!this.data) {

      this.dialogRef.close();
      return;

    }

  }

  submit(parameter: LeadFileCreateParameter) {

    parameter
      .setLeadOid(this.data?.Lead?.Oid);

     const routeData =  LeadFileRouteData().setId(parameter.Oid ?? null);

    const configuration: CtModelConfiguration<any> = CtModelConfiguration
      .create()
      .setRouteData(routeData)

    if(parameter.File){

    this._model
      .putInfos(configuration, this.serializerHelperService.objectToFormData(parameter))
      ?.subscribe(
        () => this.dialogRef.close(true),
        (error) => console.error('An error occurred during execution:', error)
      );
    }
    else{

      this._model
      .putInfos(configuration, parameter)
      ?.subscribe(
        () => this.dialogRef.close(true),
        (error) => console.error('An error occurred during execution:', error)
      );

    }

  }

  cancel(){

    this.dialogRef.close(false);

  }


}
export class LeadFileCreateParameter {

  File: File | null = null;
  Note: string | null = null;
  Lead: { Oid: number } | any | null = null;
  Type: { Oid: number } | any | null = null;
  Oid : number | null = null;

  public static create = () => new LeadFileCreateParameter();

  setFile(value: File | null): LeadFileCreateParameter {

    this.File = value;
    return this;

  }

  setNote(value: string | null): LeadFileCreateParameter {

    this.Note = value;
    return this;

  }

  setLeadOid(value: number | null): LeadFileCreateParameter {

    this.Lead = {Oid: value};
    return this;

  }

  setType(value: number | null): LeadFileCreateParameter {

    this.Type = {Oid: value};
    return this;

  }

  setOid(value: number | null): LeadFileCreateParameter {

    this.Oid = value;
    return this;

  }
}
