import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CompanyEditComponent} from "./company-edit.component";
import {CtModelModule} from "@ctsolution/ct-framework";
import {CompanyInformationModule} from "../company-information/company-information.module";
import {MatDividerModule} from "@angular/material/divider";
import {TranslateModule} from "@ngx-translate/core";
import {CompanyPlaceListModule} from "../company-place-list/company-place-list.module";
import {CompanyContactModule} from "../../company-contact/company-contact.module";
import {CompanyContractModule} from "../company-contract/company-contract.module";
import {CompanyEditFormModule} from './company-edit-form/company-edit-form.module';
import { CompanyService } from '../company.service';
import { CompanyLeadModule } from './company-lead/company-lead.module';

@NgModule({
  declarations: [CompanyEditComponent],
  imports: [
    CommonModule,
    CtModelModule,
    CompanyInformationModule,
    MatDividerModule,
    TranslateModule,
    CompanyPlaceListModule,
    CompanyContactModule,
    CompanyContractModule,
    CompanyEditFormModule,
    CompanyLeadModule
  ],
  exports: [CompanyEditComponent],
  providers: [CompanyService],
})
export class CompanyEditModule {
}
