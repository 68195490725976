import {Component, EventEmitter, Input, Output} from '@angular/core';
import {insertModeDetailsMap, MovementManagerInsertModeEnum} from "./movement-manager-insert-mode.enum";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-movement-manager-insert-mode',
  templateUrl: './movement-manager-insert-mode.component.html',
  styleUrls: ['./movement-manager-insert-mode.component.scss']
})
export class MovementManagerInsertModeComponent {

  @Output() onInsert: EventEmitter<MovementManagerInsertModeEnum | null> = new EventEmitter<MovementManagerInsertModeEnum | null>();
  @Input() enableWerehouse = false;

  form: FormGroup;
  control = new FormControl(null)

  get fxFlexValue(): number {

    return Math.round(100 / (this.getEnumKeys() ?? []).length)

  }

  constructor(private formBuilder: FormBuilder) {

    this.form = this.formBuilder
      .group({
        insertMode: this.control
      })

    this.form.valueChanges
      .subscribe(() => {

        this.onInsert.emit(this.form.value.insertMode)

      })
  }

  getEnumKeys() {

    if(this.enableWerehouse){

      return Object
      .keys(this.insertModes)
      .filter((key: any) => !isNaN(Number(this.insertModes[key])));

    }

    const stardandReturn = [this.insertModes[MovementManagerInsertModeEnum.FreeInput]]

    return stardandReturn

  }

  protected readonly insertModeDetailsMap = insertModeDetailsMap;
  protected readonly insertModes = MovementManagerInsertModeEnum;

}
