import {Component, Input} from '@angular/core';
import {
  CtBinaryOperator,
  CtDatatableActionConfiguration,
  CtModelConfiguration,
  CTModelDatatableFilter,
  CtModelOperator
} from "@ctsolution/ct-framework";
import {WorkOrderRouteData} from "../../../_core/route-data/work-order.route-data";
import {Router} from "@angular/router";
import { DEFAULT_DATATABLE_CONFIGURATION } from '../../../_core/constants';
import { FileGet } from '../../../_core/classes/file-get';
import { FileService } from '../../../_core/lib/file.service';

@Component({
  selector: 'app-company-contract',
  template: `
    <div *ngIf="configuration">

      <h5> {{ 'workorder' | translate }} </h5>

      <ct-model *ngIf="configuration" [configuration]="configuration"></ct-model>

    </div>
  `
})
export class CompanyContractComponent {

  @Input() masterConfiguration: CtModelConfiguration<any> | null = null;
  configuration: CtModelConfiguration<any> | null = null;


  fileHandlingCTA: CtDatatableActionConfiguration = new CtDatatableActionConfiguration()
    .setColor("primary")
    .setIcon("folder_zip")
    .setDynamicClass(value => !value['Files'].length ? `no-data` : null)

  datatableConfiguration = DEFAULT_DATATABLE_CONFIGURATION()
    .setCustomDatatableAction(this.fileHandlingCTA,
      (async (event: any) => {

        if(event.Files.length > 0)
          this.downloadZip(event.Files[0].Endpoint,event.Name)

        //siamo d'accordo con le api che il primo elemento sia sempre lo zip, altrimenti si avrà un type per definirlo
      }))


  constructor(private router: Router,private fileService : FileService) {
  }

  ngOnInit() {

    if (this.masterConfiguration?.RouteData?.id) {

      this.setup();

    }

  }

  setup() {

    const configuration = CtModelConfiguration
      .create()
      .setRouteData(WorkOrderRouteData())
      .setCTDatatableConfiguration(this.datatableConfiguration)
      .setDisableAutoQueryFilter(true)
      .setOnEdit(event => this.router
        .navigate(['/', 'work-order', 'edit', event.Oid]))
      .setFieldsCustomTemplates([
        {prop: 'Company', visible: false},
        {prop: 'CompanyFiscalCode', visible: false},
        {prop: 'CompanyVAT', visible: false},
        {prop: 'Quotation', visible: false}
      ])

    configuration
      .initialized()
      .subscribe(() => this.configuration?.setOperations([CtModelOperator.Update]))

    configuration
      .filterValues
      .addConstantFilter(CTModelDatatableFilter
        .create()
        .setField('Company.Oid')
        .setValue(this.masterConfiguration?.RouteData?.id)
        .setOperatorType(CtBinaryOperator.Equal))

    this.configuration = configuration;

  }

  //TODO: centralizzare?
  downloadZip(endpoint : string, workorderName : string) {

    const filename = `Documenti_${workorderName}`
    const value : FileGet ={
      Endpoint: endpoint
    };

    this.fileService.downloadAttachmentByEndPoint(value,filename)

    //TODO: potrà essere personalizzata l'etichetta in base alle esigenze
  }

}
