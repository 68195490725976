<div class="work-order-edit-container p-10">

  <mat-tab-group [(selectedIndex)]="viewModel.selectedIndex">

    <mat-tab label="Dati generali">

      <ctm-general-data #generalDataComponent *ngIf="generalDataConfiguration"
        [configuration]="generalDataConfiguration" (onSubmit)="submit($event)"></ctm-general-data>

    </mat-tab>

    <mat-tab [label]="(isQuotation ? 'Dettagli preventivo' : 'RowWorkOrder') | translate " *ngIf="viewModel.masterDetail">

      <ctm-movement-manager #movementManagerComponent
        [configuration]="movementManagerConfiguration"></ctm-movement-manager>

    </mat-tab>

    <ng-container *ngIf="viewModel.workOrderRowHandlingEnabled">

      <mat-tab [label]="'Movimentazioni/Ordini'" *ngIf="!isQuotation">

        <ctm-work-order-handlings [configuration]="workOrderHandlingsConfiguration"></ctm-work-order-handlings>

      </mat-tab>

      <mat-tab [label]="'Riepilogo Movimentazioni/Ordini'">

        <ctm-work-order-summary></ctm-work-order-summary>

      </mat-tab>

    </ng-container>

    <ng-container *ngIf="viewModel.masterDetail">

      <mat-tab [label]="'Gestione documenti'">

        <ctm-work-order-documents [configuration]="workOrderDocumentsConfiguration"></ctm-work-order-documents>

      </mat-tab>
    </ng-container>

  </mat-tab-group>

</div>
<ng-template #headerActions>

  <app-lang-download-file [configuration]="langDownloadFileConfiguration"
    (onDownload)="downloadPdf($event)"></app-lang-download-file>

</ng-template>
