import { AfterViewInit, ChangeDetectorRef, Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { CtDatatableActionConfiguration, CtModelConfiguration } from '@ctsolution/ct-framework';
import { CTMGeneralService } from '../../../_core/lib/general.service';
import { LeadRouteData } from '../../../_core/route-data/lead.route-data';
import { DEFAULT_DATATABLE_CONFIGURATION } from '../../../_core/constants';
import { StateLeadBulletConfiguration } from './state-lead-bullet/state-lead-bullet.configuration';
import { ActivatedRoute } from '@angular/router';
import { FileGet } from '../../../_core/classes/file-get';
import { FileService } from '../../../_core/lib/file.service';

@Component({
  selector: 'app-lead-list',
  templateUrl: './lead-list.component.html',
  styleUrls: ['./lead-list.component.scss']
})
export class LeadListComponent implements AfterViewInit {

  @Input() configuration: CtModelConfiguration<LeadListComponent> | null = null;
  @Input() importComponent = false;
  @ViewChild('StateCellTemplate') stateCellTemplate: TemplateRef<any> | null = null;


  fileHandlingCTA: CtDatatableActionConfiguration = new CtDatatableActionConfiguration()
    .setColor("primary")
    .setIcon("folder_zip")
    .setDynamicClass(value => !(value['Files'] && value['Files'].length) ? `no-data` : null)

    datatableConfiguration = DEFAULT_DATATABLE_CONFIGURATION()
    .setCustomDatatableAction(this.fileHandlingCTA,
      (async (event: any) => {

        if(event.Files.length > 0)
          this.downloadZip(event.Files[0].Endpoint,event.Name)

        //siamo d'accordo con le api che il primo elemento sia sempre lo zip, altrimenti si avrà un type per definirlo
      }))

  constructor(private general: CTMGeneralService, private route: ActivatedRoute, private cdr: ChangeDetectorRef, private fileService : FileService) {
  }

  ngAfterViewInit() {

    this.setup();

    if(this.importComponent) return;

    this.route
      .queryParams
      .subscribe(qp => {

        this.route
          .data
          .subscribe(data => {

            data['title'] = 'CT_PAGE.BREADCRUMBS.DetailLead';
            this.cdr.detectChanges();

          })

      });


  }

  async setup() {

    if (!this.configuration) {
      this.configuration = this.defaultSetup();
    }

    this.configuration
    .setFieldsCustomTemplates([
      {
        prop: 'LeadStatus',
        cellTemplate: this.stateCellTemplate,
        filterOptions: {
          multiple: true
        } as any
      },
    ])
    .setCTDatatableConfiguration(this.datatableConfiguration);

  }

  defaultSetup() {

      return CtModelConfiguration
      .create<LeadListComponent>()
      .setRouteData(LeadRouteData())
      .setOnCreate(() => this.general.navigateOnCTModelRouter(["lead"], "create", { qpHandling: 'preserve' }))
      .setOnEdit(event => this.general.navigateOnCTModelRouter(["lead"], "edit", {
        value: event,
        configuration: this.configuration,
        qpHandling: 'preserve'
      }))

  }

  stateBulletConfiguration = (state: string) => StateLeadBulletConfiguration
    .create(state)


    downloadZip(endpoint : string, leadname : string) {

      const filename = `Documenti_Lead_${leadname}`;

      const value : FileGet ={
        Endpoint: endpoint
      };

      this.fileService.downloadAttachmentByEndPoint(value,filename)

      //TODO: potrà essere personalizzata l'etichetta in base alle esigenze
    }
}
