import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { CtBinaryOperator, CtModelConfiguration, CTModelDatatableFilter, CtModelOperator } from '@ctsolution/ct-framework';
import { LeadListComponent } from '../../../lead/lead-list/lead-list.component';
import { LeadRouteData } from 'projects/ct-manager/src/app/_core/route-data/lead.route-data';
import { DEFAULT_DATATABLE_CONFIGURATION } from 'projects/ct-manager/src/app/_core/constants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-company-lead',
  templateUrl: './company-lead.component.html',
  styleUrls: ['./company-lead.component.scss']
})
export class CompanyLeadComponent implements OnInit {

  @Input() companyModelConfiguration: CtModelConfiguration<any> | null = null;
  configuration : CtModelConfiguration<any> | null = null;

  constructor(private router : Router) { }


  ngOnInit(): void {

    const configuration = CtModelConfiguration
    .create<LeadListComponent>()
    .setRouteData(LeadRouteData())
    .setDisableAutoQueryFilter(true)
    .setOnEdit(event => this.router
      .navigate(['/', 'lead', 'edit', event.Oid]));

  configuration
    .initialized()
    .subscribe(() => this.configuration?.setOperations([CtModelOperator.Update]))

    configuration
      .filterValues
      .addConstantFilter(
        CTModelDatatableFilter
          .create()
          .setField('Customer.Oid')
          .setValue(this.companyModelConfiguration?.RouteData?.id)
          .setOperatorType(CtBinaryOperator.Equal)
      )


    this.configuration = configuration;

  }

}
