import {Component, Input} from '@angular/core';
import {
  CtBinaryOperator,
  CtButtonConfiguration,
  CtModelConfiguration,
  CTModelDatatableFilter,
  CtModelDatatableOperators,
  CtModelRouteData,
  CtModelService,
  CtModelType,
  MAT_RAISED_PRIMARY, SnackbarService
} from "@ctsolution/ct-framework";
import {CompanyInformationService} from "./company-information.service";
import {CompanyInformationRouteData} from "../../../_core/route-data/company-information.route-data";
import {CompanyInformation} from "./company-information";
import {CtWebapiGenericResponse, CtWebapiService, DataRequest} from "@ctsolution/ct-webapi";
import { ActivatedRoute } from '@angular/router';
import { CompanyService } from '../company.service';

@Component({
  selector: 'app-company-information',
  template: `
    <h5> {{ 'Contatti' | translate }} </h5>

    <div class="company-information-container" *ngIf="modelConfiguration">

      <mat-card>

        <mat-card-content>

          <mat-card-title>

          </mat-card-title>

          <div class="ct-model-container">

            <ct-model [configuration]="modelConfiguration"></ct-model>

          </div>

          <mat-card-actions *ngIf="modelConfiguration?.form" align="end">

            <ct-button [configuration]="submitButton"></ct-button>

          </mat-card-actions>

        </mat-card-content>

      </mat-card>

    </div>`,
  styles: [`
    ::ng-deep .company-information-container {

      .ct-model-container {

        .mat-card {

          margin: 0px;
          box-shadow: none !important;

          .mat-card-content {

            padding: 0px !important;

          }

        }

      }

    }
  `]
})
export class CompanyInformationComponent {

  @Input() masterConfiguration: CtModelConfiguration<any> | null = null;

  modelConfiguration: CtModelConfiguration<any> | null = null;
  leadOid : string | null = null;

  submitButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CT_PAGE.CT_FORM.save")
    .setClass('ct-model-submit-button')
    .setAction(() => this.onSubmit())
    .setMatherialOptions(MAT_RAISED_PRIMARY);

  constructor(private companyContactService: CompanyInformationService,private companyService : CompanyService, private _model: CtModelService<any>, private _webapi: CtWebapiService, private _snackbar: SnackbarService,private route : ActivatedRoute) {
  }

  ngOnInit() {

    if (this.masterConfiguration?.RouteData?.id) {

      this.setup();

    }

  }

  setup() {

    this.leadOid = this.route.snapshot.queryParamMap.get('LeadOid');

    const configuration: CtModelConfiguration<any> = CtModelConfiguration
      .create()
      .setRouteData(
        CtModelRouteData
          .create()
          .setModelType(CtModelType.FORM)
          .setController(`${window.location.origin}/assets/demo/company-information-model.json`))
      .setDefaultActionsEnabled(false)

    this.modelConfiguration = configuration;

    const body: CtModelDatatableOperators = CtModelDatatableOperators
      .create()
      .setFilters([
        CTModelDatatableFilter
          .create()
          .setField('Company.Oid')
          .setValue(this.masterConfiguration?.RouteData?.id)
          .setOperatorType(CtBinaryOperator.Equal)
      ])

    const request: DataRequest = new DataRequest()
      .setController([CompanyInformationRouteData().controller!])
      .setAction('List')
      .setQueryParams({hideSpinner: true})
      .setBody(body)

    this._webapi
      .post<CtModelConfiguration<any>>(request)
      .subscribe((result: CtWebapiGenericResponse<CtModelConfiguration<any>>) => {

        this.modelConfiguration
          ?.setDataSource(result.Result.DataSource);

        setTimeout(() => {

          this.setupFormValue(this.modelConfiguration?.DataSource);

        }, 100)

      });

  }

  setupFormValue(value: Array<CompanyInformation>) {

    const formValue = this.modelConfiguration?.form?.value;

    this.companyContactService
      .getContactInformationTypes()
      .subscribe(companyInformationTypes => {

        Object
          .keys(formValue)
          .forEach(k => {

            const codeValue = companyInformationTypes
              .find(element => element.Description?.toLowerCase() === k.toLowerCase());

            const existingValue = value.find(element => +(element.Type ?? '0') === +(codeValue?.Code ?? '0'));

            if (existingValue) {

              this.modelConfiguration?.form?.get(k)?.setValue(existingValue.Content);

            }

          })


      });

  }

  onSubmit() {

    this.modelConfiguration
      ?.form
      ?.markAllAsTouched();

    if (this.modelConfiguration?.form?.valid) {

      const value = this.modelConfiguration.form.value;

      // if (!Object.values(value).length) return;

      const keys = Object.keys(value);

      this.companyContactService
        .getContactInformationTypes()
        .subscribe(companyInformationTypes => {

          keys
            .forEach((k, idx) => {

              const codeValue = companyInformationTypes
                .find(element => element.Description?.toLowerCase() === k.toLowerCase());

              if (codeValue) { // && !!value[k]

                const companyInformation = CompanyInformation
                  .create()
                  .setCompanyOid(this.masterConfiguration?.RouteData?.id ?? -1)
                  .setContent(value[k] ?? '')
                  .setType(codeValue.Code);

                const configuration: CtModelConfiguration<any> = CtModelConfiguration
                  .create()
                  .setRouteData(
                    CtModelRouteData
                      .create()
                      .setController(CompanyInformationRouteData().controller)
                  )

                const existingValue: CompanyInformation | undefined = this.modelConfiguration?.DataSource.find((element: CompanyInformation) => (<any>element.Type) === +(companyInformation.Type ?? '0'));

                if (existingValue) {

                  configuration
                    .RouteData
                    ?.setId(existingValue.Oid ?? -1)

                }

                this._model
                  .putInfos(configuration, companyInformation)
                  ?.subscribe(() => {

                    if (idx === (keys.length - 1)) {

                      this._snackbar.onSuccess();

                      this.modelConfiguration = null;

                      setTimeout(() => this.setup())

                      if(this.leadOid) this.companyService.navigateToLead(this.leadOid);

                    }

                  });

              }

            })

        })

    }

  }

}
